<template>
    <div class="packingBox">
        <div class="topsBox">
            <div class="trailerServer">
                <div class="selectItem">
                    <div class="ipts">
                        <el-input v-model="businessNo" placeholder="请输入业务编号/订单号/提单号/箱号"></el-input>
                    </div>
                </div>
                <div class="selectItem">
                    <div class="ipts">
                        <!-- <el-date-picker v-model="dataVal" type="date" placeholder="选择日期" value-format="yyyy-MM-dd">
                        </el-date-picker> -->
                        <el-date-picker v-model="dataVal" type="daterange" range-separator="-" start-placeholder="开始日期"
                            end-placeholder="结束日期" value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </div>
                </div>
                <div class="selectItem">
                    <div class="ipts">
                        <el-input v-model="pointAddressVal" placeholder="门点地址"></el-input>
                    </div>
                </div>
                <div class="searchBtn" @click="handleSearch">
                    <el-button type="primary" icon="el-icon-search">搜索</el-button>
                </div>
                <div class="resetBtn" @click="handleReset">
                    <span>重置</span>
                </div>
                <!-- <div class="selectItems">
                    <p>跟踪状态</p>
                    <div class="ipt">
                        <el-select v-model="trackStatusVal" placeholder="跟踪状态">
                            <el-option v-for="item in trackStatusList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div> -->
                <!-- <div class="selectItems">
                <span>产品</span>
                <div class="ipt">
                    <el-input v-model="pointAddressVal" placeholder="请选择产品"></el-input>
                </div>
            </div> -->
            </div>
            <div class="trailerServer">
                <!-- <div class="selectItem">
                    <span>港口</span>
                    <div class="ipt">
                        <el-select v-model="portVal" placeholder="请选择港口">
                            <el-option v-for="item in portList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div> -->
                <!-- <div class="selectItem">
                    <span>产品</span>
                    <div class="ipt">
                        <el-select v-model="productVal" placeholder="请选择产品">
                            <el-option v-for="item in productList" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div> -->


            </div>
        </div>

        <div class="tables">
            <div>
                <el-table :data="tableDataList" border :header-row-style="{ height: '50px' }"
                    :header-cell-style="{ background: 'rgba(153, 153, 153, .1)' }" :row-style="{ height: '56px' }"
                    style="width: 100%">
                    <!-- <el-table-column prop="date" label="跟踪状态" width="150"></el-table-column> -->
                    <el-table-column prop="packing_no" label="箱单" width="150"></el-table-column>
                    <el-table-column prop="province" label="司机/箱资料" width="150">
                        <template>
                            <div style="color: #1890FF;cursor: pointer;">
                                复制
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="city" label="司机信息" width="150">
                        <template slot-scope="scope">
                            <p v-if="scope.row.driver_name">{{ scope.row.driver_name }}</p>
                            <p v-if="scope.row.driver_plate">{{ scope.row.driver_plate }}</p>
                            <p v-if="scope.row.driver_mobile">{{ scope.row.driver_mobile }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="box_making_time" label="做箱时间" width="200"></el-table-column>
                    <el-table-column prop="cut_off_time_beg" label="截单时间" width="200">
                        <template slot-scope="scope">
                            <div>
                                <p v-if="scope.row.cut_off_time_beg">{{ scope.row.cut_off_time_beg }}</p>

                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="bill_no" label="提单号" width="150"></el-table-column>
                    <el-table-column prop="shipping" label="箱型" width="150"></el-table-column>
                    <el-table-column prop="packing_no" label="箱号" width="150"></el-table-column>
                    <el-table-column prop="seal_no" label="封号" width="150"></el-table-column>
                    <!-- <el-table-column label="皮重(kg)" width="150"></el-table-column> -->
                    <el-table-column prop="dock" label="港区" width="150"></el-table-column>
                    <!-- <el-table-column label="堆场" width="150"></el-table-column> -->
                    <el-table-column prop="store_address" label="门点地址" width="150"></el-table-column>
                    <el-table-column prop="voyage" label="船名航次" width="150"></el-table-column>
                    <!-- <el-table-column label="下单时间" width="150"></el-table-column> -->
                </el-table>
            </div>

            <!-- 分页 -->
            <div class="paging">
                <el-pagination background @current-change="handleSizeChange" layout="prev, pager, next"
                    :total="paging.total" :page-size="paging.size">
                </el-pagination>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { baseUrl } from '../../tools/baseUrl';
export default {
    name: "packingListQuery_Component",
    data() {
        return {
            businessNo: "",//业务编号
            dataVal: "",//日期
            pointAddressVal: "",//门点地址
            trackStatusVal: "",//跟踪状态
            trackStatusList: [],//跟踪状态数据
            portVal: "",//港口
            portList: "",//港口数据
            productVal: "",//产品
            productList: "",//产品数据
            tableDataList: [{
                date: '2016-05-02',
                name: '王小虎',
                province: '上海',
                city: '普陀区',
                address: '上海市普陀区金沙江路 1518 弄',
                zip: 200333
            }],
            paging: {
                total: 0,
                size: 5,
            },
        }
    },
    created() {
        this.getPackingList()
    },
    methods: {
        handleSizeChange() {  //分页

        },
        getPackingList() {
            let token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/trailerPacking/packingList`, {
                headers: { 'authorization': token }
            }).then(res => {
                console.log(res.data.result.records)
                this.tableDataList = res.data.result.records
            })
        },
        handleSearch() {
            let token = sessionStorage.getItem("token");
            var dataVal1 = this.dataVal[0];
            var dataVal2 = this.dataVal[1];
            axios.get(`${baseUrl}/trailerPacking/packingList`, {
                params: {
                    keywords: this.businessNo,
                    store_address: this.pointAddressVal,
                    begTime: dataVal1,
                    endTime: dataVal2
                },
                headers: { 'authorization': token }
            }).then(res => {
                console.log(res.data.result.records)
                this.tableDataList = res.data.result.records
            })
        },
        handleReset() { //重置
            this.businessNo = this.dataVal = this.pointAddressVal = this.trackStatusVal = this.portVal = this.productVal = '';
            this.getPackingList()
        }
    },
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 649px;
    //     /*滚动条宽度*/
    height: 8px;
    //     /*滚动条高度*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 0px;
    /*滚动条的背景区域的圆角*/

    background: rgba(153, 153, 153, 0.2);
    /*滚动条的背景颜色*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    /*滚动条的圆角*/

    background: #999999;
    /*滚动条的背景颜色*/
}

::v-deep .el-table .el-table__cell {
    padding: 0;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
}

::v-deep .el-table .cell {
    text-align: center;
}

::v-deep .el-table th.el-table__cell>.cell {
    font-size: 14px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
}

.packingBox {
    margin: 0 auto;
    // min-height: 690px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    // padding-top: 24px;
    // padding-left: 32px;
    // padding-bottom: 23px;

    .topsBox {
        padding: 24px 0 24px 0;
        background: #FFFFFF;
        border-bottom: 1px solid rgba(153, 153, 153, 0.3);
    }

    .trailerServer {
        padding: 0 24px;
        display: flex;
        margin-top: 16px;

        .selectItem {
            display: flex;
            align-items: center;
            min-width: 240px;
            height: 40px;
            // background: #FFFFFF;
            border-radius: 4px 4px 4px 4px;
            margin-left: 32px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;

            .ipts {
                width: 240px;
            }

            .el-date-editor {
                width: 100%;
            }

            .ipt {
                margin-left: 16px;
            }
        }

        .selectItem:nth-child(1) {
            margin-left: 0;
        }

        .selectItems {
            display: flex;
            align-items: center;
            margin-left: 32px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #333333;

            .ipt {
                width: 180px;
                height: 40px;
                background: #FFFFFF;
                border-radius: 4px 4px 4px 4px;
                margin-left: 16px;
            }
        }

        .searchBtn {
            margin-left: 68px;
            width: 120px;
            height: 40px;
            background: #FFC13B;
            box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
            border-radius: 4px 4px 4px 4px;
            overflow: hidden;

            .el-button {
                width: 100%;
                height: 100%;
                background: #FFC13B;
                border-color: transparent;
                font-size: 16px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #2B2B2B;
                text-align: center;
                // line-height: 40px;
                padding: 0;
                line-height: 40px;
            }
        }

        .resetBtn {
            width: 80px;
            height: 40px;
            border-radius: 4px 4px 4px 4px;
            text-align: center;
            line-height: 40px;
            font-size: 16px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #2B2B2B;
            border: 1px solid rgba(43, 43, 43, .4);
            margin-left: 32px;
            cursor: pointer;
        }
    }

    .trailerServer:nth-child(1) {
        margin-top: 0;
    }

    .tables {
        padding: 0 24px 32px;
        margin-top: 32px;

        .paging {
            display: flex;
            justify-content: flex-end;
            position: relative;
            // float: right;
            margin-top: 32px;
            // background-color: #FFFFFF;
        }
    }
}
</style>