<template>
    <div>
        <div>
            <packingListQuery_Component></packingListQuery_Component>
        </div>
    </div>
</template>

<script>
import packingListQuery_Component from '../../components/YunShu/packingListQuery_Component.vue';
export default {
    name: "packingListQuery",
    components: { packingListQuery_Component }
}
</script>

<style lang="scss" scoped>

</style>